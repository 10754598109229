import Axios from "axios";
import { langList, regionNoCn, regionAll } from "@/utils/regions";
import { getIp } from '@/services/request'

const getRegion = async () => {
    const isBrowser = typeof window !== "undefined" && window
    const getUrlParam = () => {
        const urlIpCode = isBrowser && window.location.pathname.split('/')[1]
        return urlIpCode
    }
    const getLocalParam = () => {
        const localRegion = isBrowser && localStorage.getItem('region');
        const localIpCode = !localRegion ? "" : localRegion.toLowerCase();
        return localIpCode;
    }
    const checkIp = async () => {
        let ipCode = ''
        await getIp().then(res => {
            const { code, data } = res?.data
            if (code == 2000) {
                let country = data.country.toLowerCase();
                if (regionAll.indexOf(country) != -1) {
                    ipCode = country;
                } else {
                    ipCode = 'base'
                }
            }
        }).catch(err => {
            console.log(err)
        })
        return ipCode
    }

    const getUrl = async () => {
        let url = 'base'
        const urlIp = getUrlParam()
        const localIp = getLocalParam()
        const regionIp = await checkIp();
        if (regionAll.indexOf(urlIp) > -1) {
            url = urlIp
            window.localStorage.setItem('region', url.toUpperCase());
            window.localStorage.setItem('lang', langList[url]);
        }
        else if (!!localIp || checkUA()) {
            // url不存在国家，localstorage region有值 说明非首次进入 不进行checkIp
            url = 'base'
            window.localStorage.setItem('region', url.toUpperCase());
            window.localStorage.setItem('lang', langList[url]);
        }
        else {
            // url国家无中日韩 且localstorage存在 按首次进入处理 
            url = regionIp
            window.localStorage.setItem('region', url.toUpperCase());
            window.localStorage.setItem('lang', langList[url]);
        }
        return url;
    }
    return getUrl();
}

// 检测userAgent是否包含LarkUrl 以判断是否是飞书爬虫在浏览页面
const checkUA = () => {
    if (window.navigator.userAgent.indexOf('LarkUrl') > -1) {
        // 存在LarkUrl 是飞书爬虫
        return true;
    }
    return false;
}

const goRegion = () => {
    const goHref = async () => {
        let url = await getRegion()
        const ifUrlHaveIp = regionAll.indexOf(window.location.pathname.split('/')[1]) > -1;
        let path = window.location.pathname
        if (ifUrlHaveIp) {
            path = `/${path.split('/').slice(2).join('/')}`;
        }

        if (regionNoCn.indexOf(window.location.pathname.split('/')[1]) > -1) {
            // url国家带有'jp', 'kr'则不做跳转
            return;
        } else if (url == 'base') {
            // 只要是base（localstorage或者checkIp）不做跳转
            return;
        } else if (url == 'cn') {
            // cn（url国家或者checkIp）跳转到cn首页
            window.location.href = `${process.env.url_cn}/${path.substring(1)}`;
            return;
        } else {
            window.location.pathname = `/${url}${window.location.pathname}`
        }
    }

    goHref()
}

export { goRegion, getRegion } 