import React, { useEffect, useLayoutEffect, useState } from "react"
import { unstable_batchedUpdates } from "react-dom";
import { IntlProvider } from "react-intl"
import { langList, regionAll } from "@/utils/regions";
import { object } from "prop-types";

export const LangStateContext = React.createContext()
export const LangSetStateContext = React.createContext()
const isBrowser = typeof window !== "undefined" && window

const LangProvider = ({ children, OVERRIDE_LANG }) => {
  const [enLangData, setenLangData] = useState(null)
  const [zhLangData, setzhLangData] = useState(null)
  const [jaLangData, setjaLangData] = useState(null)
  const [koLangData, setkoLangData] = useState(null)
  const [thLangData, setthLangData] = useState(null)
  const [deLangData, setdeLangData] = useState(null)
  const [frLangData, setfrLangData] = useState(null)
  const [itLangData, setitLangData] = useState(null)
  const [nlLangData, setnlLangData] = useState(null)

  const [lang, setLang] = useState('')
  const regionUrl = isBrowser && window.location.pathname.split('/')[1]

  const langDataConfig = {
    'en': enLangData,
    'zh': zhLangData,
    'ja': jaLangData,
    'ko': koLangData,
    'th': thLangData,
    'de': deLangData,
    'de-en': enLangData,
    'fr': frLangData,
    'fr-en': enLangData,
    'it': itLangData,
    'it-en': enLangData,
    'us': enLangData,
    // 'nl': nlLangData,
    'nl-en': enLangData,
    'cz-en': enLangData,
    'es-en': enLangData,
  }


  useLayoutEffect(() => {
    getUrlLang();
    getLanguageJson();
  }, [])

  const getUrlLang = () => {
    if (regionAll.indexOf(regionUrl) > -1) {
      setLang(langList[regionUrl]);
    } else {
      setLang(langList['base']);
    }
  }


  const getLanguageJson = () => {
    Promise.resolve().then(() => {
      let langAry = [...new Set(Object.values(langList))];
      unstable_batchedUpdates(async () => {
        langAry.map((item => {
          getLanAjax(item)
        }))
      })
    })
  }
  const myCustomErrorFunction=()=>{
    //自定义intl报错信息
  }
  const setLangFuntion = {
    'en': setenLangData,
    'zh': setzhLangData,
    'ja': setjaLangData,
    'ko': setkoLangData,
    'th': setthLangData,
    'de': setdeLangData,
    'fr': setfrLangData,
    'it': setitLangData,
    'nl': setnlLangData,
  }
  const getLanAjax = (lang) => {
    fetch(
      `${process.env.resourceUrl}locales/${lang}.json`
    ).then(response => response.json()).then(res => {
      let fn = setLangFuntion[lang]
      fn(res)
    })
  }


  if (enLangData !== null && zhLangData !== null) {
    return (
      <LangStateContext.Provider value={lang}>
        <LangSetStateContext.Provider value={setLang}>
          <IntlProvider locale={lang} messages={langDataConfig[lang]}  onError={myCustomErrorFunction}>
            {children}
          </IntlProvider>
        </LangSetStateContext.Provider>
      </LangStateContext.Provider>
    )
  } else {
    return null
  }
}

export { LangProvider }
