module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["jp","kr","th","uk","de","de-en","fr","fr-en","it","it-en","us","cz-en","nl-en","es-en"],"defaultLanguage":"","redirect":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"supportedLngs":["jp","kr","th","uk","de","fr","it","us","cz","nl","es"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"xreal","short_name":"xreal","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/xreal_logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e05bf85b1485920a932e99a79cf9ba3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1099195167315023"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://dca8a08d007649609dec48d210491317@o4505272936366080.ingest.sentry.io/4505273245040640","tracesSampleRate":0.005,"environment":"production","browserTracingOptions":{"traceXHR":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
