import crypto from "crypto"
export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getParameterByName = name => {
  const para = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp(`[\\?&]${para}=([^&#]*)`)

  const results = regex.exec(window.location.search)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "))
}

export const generateNonce = (length = 32) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = ""
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
// 计算SHA256 HMAC的函数
function calculateSHA256HMAC(key, data) {
  const hmac = crypto.createHmac("sha256", key)
  hmac.update(data)
  return hmac.digest("base64")
}

// 处理数据的函数
function processData(data) {
  // 过滤掉值为空的参数
  const filteredData = Object.keys(data)
    .filter(
      key => data[key] !== null && data[key] !== undefined && data[key] !== ""
    )
    .reduce((obj, key) => {
      obj[key] = data[key]
      return obj
    }, {})
  // 按照参数名ASCII码从小到大排序
  const sortedKeys = Object.keys(filteredData).sort((a, b) =>
    a.localeCompare(b)
  )
  // 拼接成URL键值对的格式字符串
  const stringA = sortedKeys.map(key => `${key}=${filteredData[key]}`).join("&")
  return stringA
}

export const generateSign = (method, url, timeStamp, nonce, data) => {
  let startIndex = url.indexOf("/isc")
  let match = url.substring(startIndex)
  // .replace('/isc', '');
  let stringA = processData(data)
  let result = ""

  let stringSignTemp =
    method.toUpperCase() +
    "\n" +
    match +
    "\n" +
    timeStamp +
    "\n" +
    nonce +
    "\n" +
    stringA +
    "\n"
  console.log(stringSignTemp)
  let key = process.env.SIGNATUREKEY
  result = calculateSHA256HMAC(key, stringSignTemp)
  console.log(result, "result")
  return result
}

export const isValidAmazonOrderNumber = orderNumber => {
  const amazonOrderPattern = /^[A-Z0-9]{3}-[0-9]{7}-[0-9]{7}$/
  return amazonOrderPattern.test(orderNumber)
}

export const isValidSN = sn => {
  const SNPattern = /^[A-Z0-9]{14}$/
  return SNPattern.test(sn)
}

export const isMobileDevice = () => {
  return (
    /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    window.innerWidth <= 768
  )
}
