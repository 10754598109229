/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-09-21 16:38:29
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-18 21:45:57
 */
import http from "./axios"
const baseUrl = process.env.GATSBY_API_URL + "/api"
const baseUrl2 = process.env.GATSBY_API_URL_2
/**
 * 获取
 */
const getIp = (params) => {
	return http("get", `${baseUrl2}/v1/checkip`, params)
}


/**
 * 根据表头国家获取兼容性数据
 */
const getAdaptByCountry = (params) => {
	return http("get", `${baseUrl}/nebula/v1/isc/nebula/model/country`, params)
}
/**
 * getAppShow
 */
const getAppShow = (params) => {
	return http("get", `${baseUrl}/hackathon/v1/isc/app/show`, params)
}

/**
 * 获取nrealapp下载链接文件
 */
const getNrealapp = (params) => {
	return http("get", `${baseUrl}/nebula/v1/isc/software/version/latest`, params)
}

/**
 * 获取pptos-国家
 */
const getPolicyCountry = (params, options) => {
	return http("get", `${baseUrl}/nebula/v1/isc/dict/code`, params, options)
}

/**
 * 获取pptos--语言
 */
const getPolicyLang = (params, options) => {
	return http("get", `${baseUrl}/nebula/v1/isc/policy/country/langs`, params, options)
}

/**
 * 获取pptos--md
 */
const getPolicyMd = (params, options) => {
	return http("get", `${baseUrl}/nebula/v1/isc/i18n/policy`, params, options)
}

/**
 * 获取pptos--h5（暂无测试数据，未测试，需测试后使用）
 */
const getMdUrl = (params, options) => {
	return http("get", `${baseUrl}/nebula/v1/isc/i18n/policy`, params, options)
}

/**
 * 获取职位（暂未使用，使用需测试）
 */
const getJobs = (params) => {
	return http("get", `${baseUrl}/portal-service/isc/jobs`, params)
}

const getOtaVesion = (params) => {
	return http("get", `${baseUrl}/nebula/v1/isc/dict/code?code=web_hardware_version_en`, params)
}

/**
 * 判断是否命中问卷投放
 */
const getQsMatch = (params) => {
	return http("post", `${baseUrl}/pilot/v1/isc/questionnaire-launch/match`, params)
}

/**
 * 获取问卷模板
 */
const getQsTemplate = (params) => {
	return http("get", `${baseUrl}/pilot/v1/isc/questionnaire-template`, params)
}

/**
 * 提交问卷
 */
const qsCommit = (params) => {
	return http("post", `${baseUrl}/pilot/v1/isc/questionnaire/commit`, params)
}

const getSensorsUrl = (params) =>{
	return http('get',`${baseUrl2}/v1/data-web-domain?domain=www.xreal.com/${params}`)
}



/*
* 查验领劵资格
*/ 
const verifyCoupononline = (params) =>{
	return http("post", `${baseUrl}/pilot/v1/isc/campaign/2024-618/challenge-online`, params)
}
/*
* 查验领劵资格
*/ 
const verifyCouponoffline = (params) =>{
	return http("post", `${baseUrl}/pilot/v1/isc/campaign/2024-618/challenge-offline`, params)
}

export {
	getIp,
	getSensorsUrl,
	getOtaVesion,
	getAdaptByCountry,
	getAppShow,
	getNrealapp,
	getJobs,
	getPolicyCountry,
	getPolicyLang,
	getPolicyMd,
	getQsMatch,
	getQsTemplate,
	qsCommit,
	verifyCoupononline,
	verifyCouponoffline
}
