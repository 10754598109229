import { getSensorsUrl } from '../services/request'
import getCountry from '@/utils/getCountry.js'
const getSensors_url = async () => {
    const countryCode = getCountry()
    let region = countryCode.split('-')[0]
    if (region == 'base') {
        region = ' '
    }
    let url = ''
    await getSensorsUrl(region).then((res) => {
        if (res?.data) {
            if (process.env.GATSBY_MODE == 'production') {
                url = res.data.prod
            } else {
                url = res.data.uat
            }
        }else{
            throw res
        }
    }).catch(err => {
        throw err
    })
    return url
}

export default getSensors_url