import React, { useEffect, useLayoutEffect, useState } from "react"
import { goRegion } from '../utils/getRegion.js'
import { regionAll } from "@/utils/regions";

export const RegionContext = React.createContext();
export const RegionSetStateContext = React.createContext();
const isBrowser = typeof window !== "undefined" && window;

const RegionProvider = ({ children, OVERRIDE_LANG }) => {

  const [region, setRegion] = useState(null);

  let regionUrl = isBrowser && window.location.pathname.split('/')[1]

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    let regionLocalStorage = window.localStorage.getItem('region')?.toLowerCase();
    getUrlRegion();

    //当www.xreal.cn跳转到国际站时 判断参数是否含有region=base
    const urlParam = window.location.search?.split('?')[1]
    const path = window.location.pathname
    if (urlParam && urlParam.includes('region=base')) {
      window.localStorage.setItem('region', 'BASE')
      window.localStorage.setItem('lang', 'en')
      window.location.href = `${window.location.origin}${path}`;
      return;
    }

    if (regionUrl == 'cn' || regionLocalStorage !== regionUrl) {
      // url国家是cn或者url国家与localstorage存的国家不同
      goRegion();
    }
    // if (!!regionLocalStorage && regionLocalStorage !== null) {
    //   if (['jp', 'kr', 'cn', 'base'].indexOf(regionLocalStorage) == -1) {
    //     window.localStorage.setItem('region', null)
    //     regionLocalStorage = null
    //   }
    // }

    // if (regionUrl == 'cn') {
    //   goRegion()
    // } else if (['jp', 'kr'].indexOf(regionUrl) == -1) {
    //   regionUrl = 'base'
    // }
  }

  const getUrlRegion = () => {
    if (regionAll.indexOf(regionUrl) > -1) {
      setRegion(regionUrl);
    } else {
      setRegion('base');
    }
  }

  return (
    <RegionContext.Provider value={region}>
      <RegionSetStateContext.Provider value={setRegion}>
        {children}
      </RegionSetStateContext.Provider>
    </RegionContext.Provider>
  )
}

export { RegionProvider }
