import { regionAll } from "@/utils/regions";
const isBrowser = typeof window !== "undefined" && window

const getCountry = () => {
    let country = ''
    const urlIpCode = isBrowser && window.location.pathname.split('/')[1]
    if (regionAll.indexOf(urlIpCode) > -1) {
        country = urlIpCode;
    } else {
        country = 'base'
    }
    return country;
}

export default getCountry;